















































import {Ref, Vue, Prop, Component, Watch} from 'vue-property-decorator';
import {ActionResult} from '@/models/ActionResult';
import {SettingColumn} from "@/models/settingColumn/SettingColumn";
import {SettingColumnService} from '@/services/SettingColumnService';
import {SettingColumnResult, SettingColumnSelect} from "@/models/settingColumn/SettingColumnResult";
import _each from "lodash/each";
import _filter from "lodash/filter";
import _debounce from 'lodash/debounce';
import draggable from 'vuedraggable'

@Component({
    components: {
        draggable
    }
})

export default class SettingColumns extends Vue {
    @Prop() defaultColumns: any
    @Prop() config!: SettingColumnResult
    @Prop({required: true}) module!: string
    @Prop() columnDefs!: any
    @Prop() gridApi!: any;
    @Ref('keyword') keywordInputElement!: any;
    @Prop({default: () => false}) isUsingLocalByModule: boolean;

    columns: SettingColumnSelect[] = [];
    data: SettingColumnSelect[] = [];
    popupActive: boolean = false;
    columnConfig: SettingColumnResult = new SettingColumnResult();
    keyword: string = '';
    debounce: any;

    private settingColumnService: SettingColumnService;

    constructor() {
        super();

        this.settingColumnService = new SettingColumnService();
    }

    @Watch('columns')
    getColumns(value: SettingColumnSelect[]) {
        this.data = value;
    }

    @Watch('keyword')
    searchField(value: string) {
        if (this.debounce) {
            this.debounce.cancel();
        }

        this.debounce = _debounce(() => this.data = _filter(this.columns, (item: SettingColumnSelect) => {
            return !value || item.name.toLowerCase().indexOf(value.toLowerCase()) > -1;
        }), 100);
        this.debounce();
    }

    openSettingColumnModal() {
        this.columnConfig = this.config;
        this.keyword = '';
        if (this.columnConfig) {
            let columns = this.columnConfig.columnConfigData;
            let moduleName = this.columnConfig.moduleName;

            if (columns && columns.length > 0) {
                if (this.isUsingLocalByModule) {
                    _each(columns, (column: SettingColumnSelect, key) => {
                        column.name = this.$t(`${moduleName}.${column.field}`) as string;
                        //column.name =this.$t(column.name) as string;
                    });
                }

                this.columns = columns;
            } else {
                this.columns = this.defaultColumns;
            }
        } else {
            this.columns = this.defaultColumns;
        }

        this.popupActive = true;

        setTimeout(() => {
            this.keywordInputElement.$el.querySelector('input').focus();
        }, 100)
    }

    backToDefaultSettingColumn() {
        if (this.isUsingLocalByModule) {
            _each(this.defaultColumns, (column: SettingColumnSelect, key) => {
                column.name = this.$t(`${this.module}.${column.field}`) as string;
                //column.name = this.$t(column.name) as string;
            });
        }
        this.columns = this.defaultColumns;
        this.keyword = '';
    }

    async saveSetting() {
        let result: ActionResult<SettingColumnResult>;
        let settingColumn = new SettingColumn();
        settingColumn.moduleName = this.module;
        settingColumn.columnConfigData = this.columns;

        if (this.columnConfig && this.columnConfig.id) {
            try {
                result = await this.settingColumnService
                    .update(this.columnConfig.id, settingColumn);
            } catch (e) {
                this.popupActive = false;

                return false;
            }
        } else {
            try {
                result = await this.settingColumnService.insert(settingColumn);
            } catch (e) {
                this.popupActive = false;

                return false;
            }
        }

        if (result.code > 0) {
            this.columnConfig = result.data as SettingColumnResult;
            this.agGridRefresh(this.columnConfig);

            this.$emit('updatedSuccess', this.columnConfig);
        }

        this.popupActive = false;
    }

    agGridRefresh(config: SettingColumnResult) {
        if (!config) {
            return;
        }

        let columns = config.columnConfigData
            .filter((m: any) => m.isSelected)
            .map((m: any) => m.field);

        let columnDefs = _filter(this.columnDefs, (column, key) => {
            return columns.indexOf(column.field) > -1 || column.isDefault;
        });

        this.mapOrder(columnDefs, columns, 'field')
        this.gridApi.setColumnDefs([]);
        this.gridApi.setColumnDefs(columnDefs);
    }

    mapOrder(array: any, order: any, key: any) {
        array.sort(function (a: any, b: any) {
            let A = a[key], B = b[key];

            if (order.indexOf(A) > order.indexOf(B)) {
                return 1;
            } else {
                return -1;
            }

        });

        return array;
    }
}

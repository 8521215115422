import {Attribute} from "@/models/attribute/Attribute";

export class Filter {
    id: string;
    name: string;
    routerName: string | undefined;
    userId: string;
    fullName: string;
    operatorJson: string;
    concurrencyStamp: string;
    attributes: Attribute[];

    constructor(id = '', name = '', routerName = '', operatorJson = '',
                concurrencyStamp = '', attributes = [], userId = '', fullName = '') {
        this.id = id;
        this.name = name;
        this.routerName = routerName;
        this.operatorJson = operatorJson;
        this.concurrencyStamp = concurrencyStamp;
        this.attributes = attributes;
        this.userId = userId;
        this.fullName = fullName;
    }
}
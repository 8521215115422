
















































































import {Component, Emit, Prop, Ref, Vue, Watch} from 'vue-property-decorator';
import vSelect from 'vue-select';
import {FilterAttributeResult} from "@/models/attribute/FilterAttributeResult";
import {
    AttributeType,
    ListOperator,
    ListOperatorSuggestion,
    LogicType,
    OperatorType
} from "@/constants/Attribute";
import FilterAttribute from "@/components/filter/filter-attribute/filter-attribute.vue";
import map from 'lodash/map';
import each from 'lodash/each';
import first from 'lodash/first';
import filter from 'lodash/filter';

import {Operator} from "@/models/attribute/Operator";
import {AttributeValueResult} from "@/models/attribute/AttributeValueResult";
import {Attribute} from "@/models/attribute/Attribute";
import {FilterService} from "@/components/filter/FilterService";
import {Filter} from "@/models/filter/filter";
import {AttributeValue} from "@/models/attribute/AttributeValue";
import {ActionResult} from "@/models/ActionResult";

@Component({
    components: {
        'v-select': vSelect,
        'filter-attribute': FilterAttribute
    }
})

export default class FilterModal extends Vue {
    @Prop({default: () => []}) data!: FilterAttributeResult[];
    @Prop({default: () => []}) attributeOptions: FilterAttributeResult[];
    @Prop({default: () => 500}) debounceTime: number;
    @Prop({default: () => 'Lọc khách hàng'}) title!: string;
    @Prop({default: () => ''}) moduleName: string;
    @Ref('filterNameInputElement') filterNameInputElement!: any;

    isShow: boolean = false;
    isShowEnterFilterNamePrompt: boolean = false;
    filterName = '';
    filter: Filter = new Filter();
    attributeDefault = '';
    listOperation: Operator[] = ListOperator;
    listFilterAttributeResult: FilterAttributeResult[] = [];

    private filterService = new FilterService();

    constructor() {
        super();
    }

    @Watch('data')
    getDataFilterAttributeResult(data: FilterAttributeResult[]) {
        this.listFilterAttributeResult = data;
    }

    @Watch('isShowEnterFilterNamePrompt')
    onPromptShow(value: boolean) {
        if (value) {
            setTimeout(() => {
                this.filterNameInputElement.$el.querySelector('input').focus();
            }, 100);
        }
    }

    @Emit('onAccept')
    onAccept(attributes: FilterAttributeResult[], isSave: boolean, filter: Filter) {
        return {
            attributes, isSave, filter
        };
    }

    get validName() {
        return this.filterName.length > 0;
    }

    show(value: Filter, filterAttributeResult: FilterAttributeResult[]) {
        this.isShow = true;
        this.filter = value;
        this.listFilterAttributeResult = filterAttributeResult;
        if (value !== null && value !== undefined && value.id !== '-1' && value.id !== '') {
            this.filterName = value.name;
        } else {
            this.filterName = '';
            this.filter = new Filter();
        }
    }

    accept(isSave: boolean = false) {
        if (isSave) {
            const existsValue = this.checkValue();
            if (existsValue) {
                this.isShowEnterFilterNamePrompt = true;
                // this.onAccept(this.listFilterAttributeResult, isSave, this.filter);
                this.isShow = false;
            }
        } else {
            let filter: any = this.filter;
            if (filter || filter.id === '') {
                filter.id = '-1';
                filter.name = 'Tìm kiếm ...';
            }

            this.filter.attributes = this.convertListAttributeResultToAttribute(this.listFilterAttributeResult);

            this.onAccept(this.listFilterAttributeResult, isSave, filter);
            this.isShow = false;
        }
    }

    cancel() {
        this.isShow = false;
        this.listFilterAttributeResult = [];
    }

    async acceptEnterFilterName() {
        this.filter.name = this.filterName;
        this.filter.operatorJson = '';
        this.filter.routerName = this.moduleName;
        this.filter.concurrencyStamp = this.filter.concurrencyStamp;

        this.checkValue();

        this.filter.attributes = this.convertListAttributeResultToAttribute(this.listFilterAttributeResult);

        if (this.filter.id !== '' && this.filter.id !== undefined && this.filter.id !== '-1') {
            await this.filterService.update(this.filter.id, this.filter).then((result: ActionResult<Filter>) => {
                if (result.code > 0) {
                    this.$vs.notify({
                        title: "Thông báo",
                        text: result.message,
                        color: 'success',
                        iconPack: 'feather',
                        icon: 'icon-info'
                    });

                    this.isShow = false;
                    if (result.data) {
                        this.filter.id = result.data.id;
                        this.filter.concurrencyStamp = result.data.concurrencyStamp;
                    }
                    this.onAccept(this.listFilterAttributeResult, true, this.filter);
                }
            }).catch((e) => {
                this.$vs.notify({
                    title: '',
                    text: e.message,
                    color: 'danger'
                });
            });
        } else {
            await this.filterService.insert(this.filter).then((result: ActionResult<Filter>) => {
                if (result.code > 0) {
                    this.$vs.notify({
                        title: "Thông báo",
                        text: result.message,
                        color: 'success',
                        iconPack: 'feather',
                        icon: 'icon-info'
                    });
                    this.isShow = false;
                    this.filterName = '';
                    if (result.data) {
                        this.filter.id = result.data.id;
                        this.filter.concurrencyStamp = result.data.concurrencyStamp;
                    }
                    this.onAccept(this.listFilterAttributeResult, true, this.filter);
                }
            }).catch((e) => {
                this.$vs.notify({
                    title: '',
                    text: e.message,
                    color: 'danger'
                });
            });
        }
    }

    onSelectAttributeOption(attributeOption: FilterAttributeResult, filterAttributeResult: FilterAttributeResult) {
        if (filterAttributeResult) {
            this.getFilterAttributeResult(attributeOption, filterAttributeResult);
            filterAttributeResult.attributeValues = [];
            filterAttributeResult.attributeValueId = map(filterAttributeResult.attributeValues, (item: AttributeValueResult) => {
                return item.id;
            })
        }
    }

    onSelectAttributeDefault(value: FilterAttributeResult) {
        this.attributeDefault = '';
        const filterAttributeResult = new FilterAttributeResult();
        this.getFilterAttributeResult(value, filterAttributeResult);

        this.listFilterAttributeResult.push(filterAttributeResult);
    }

    removeAttribute(index: number) {
        this.$delete(this.listFilterAttributeResult, index);
    }

    private checkValue() {
        const listFilterAttribute = filter(this.listFilterAttributeResult, (item: FilterAttributeResult) => {
            return item.attributeValues.length > 0 || item.value || item.valueTo
        })

        if (!listFilterAttribute || listFilterAttribute.length === 0) {
            this.$vs.notify({
                title: '',
                text: 'Vui lòng nhập giá trị cho các trường đã chọn',
                color: 'danger'
            });
            return false;
        } else {
            return true;
        }
    }

    // chuyển đổi tử attributeOption to attributeResult
    private getFilterAttributeResult(attributeOption: FilterAttributeResult, filterAttributeResult: FilterAttributeResult) {
        filterAttributeResult.attributeId = attributeOption.attributeId;
        filterAttributeResult.attributeName = attributeOption.attributeName;
        filterAttributeResult.type = attributeOption.type;
        filterAttributeResult.dataType = attributeOption.dataType;
        filterAttributeResult.attributeOptions = attributeOption.attributeOptions;
        filterAttributeResult.isMultiple = attributeOption.isMultiple;

        filterAttributeResult.urlSuggestion = attributeOption.urlSuggestion;
        if (filterAttributeResult.type === AttributeType.CheckList || filterAttributeResult.type === AttributeType.DropdownList) {
            filterAttributeResult.listOperator = ListOperatorSuggestion;
        } else {
            filterAttributeResult.listOperator = filter(this.listOperation, (operator: Operator) => {
                return operator.dataType.indexOf(filterAttributeResult.dataType) > -1;
            })
        }

        const operatorDefault: any | undefined = first(filterAttributeResult.listOperator);
        if (operatorDefault) {
            filterAttributeResult.operator = operatorDefault.id;
        }
    }

    // Chuyển đổi tử FilterAttributeResult to attributeValue
    private getAttributeValue(attributeResult: FilterAttributeResult): AttributeValue[] {
        if (attributeResult) {
            if ((attributeResult.type === AttributeType.Input && attributeResult.isMultiple)
                || attributeResult.type === AttributeType.CheckList
                || attributeResult.type === AttributeType.DropdownList) {

                const attributeValue: AttributeValue[] = map(attributeResult.attributeValues, (attribute: AttributeValueResult) => {
                    return {
                        id: attribute.id ? attribute.id.toString() : null,
                        name: attribute.name,
                        operator: attributeResult.operator,
                        logic: attributeResult.operator === OperatorType.NotEqual ? LogicType.And : LogicType.Or
                    }
                })

                return attributeValue
            }

            if (attributeResult.type === AttributeType.NumberInput || attributeResult.type === AttributeType.DateTime) {
                if (attributeResult.operator !== OperatorType.Between) {
                    return [{
                        id: attributeResult.value.toString(),
                        name: '',
                        operator: attributeResult.operator,
                        logic: LogicType.And
                    }]
                } else {
                    return [{
                        id: attributeResult.value,
                        name: '',
                        operator: OperatorType.GreaterThanOrEqual,
                        logic: LogicType.And
                    }, {
                        id: attributeResult.valueTo,
                        name: '',
                        operator: OperatorType.LessThanOrEqual,
                        logic: LogicType.And
                    }]
                }
            }

            if (attributeResult.type === AttributeType.Input && !attributeResult.isMultiple) {
                return [{
                    id: attributeResult.value,
                    name: '',
                    operator: attributeResult.operator,
                    logic: LogicType.And
                }]
            }
        }
        return [];
    }

    private convertListAttributeResultToAttribute(listFilterAttributeResult: FilterAttributeResult[]) {
        const attributes: Attribute[] = [];
        each(listFilterAttributeResult, (item: FilterAttributeResult) => {
            const attribute = new Attribute(item.id, item.attributeId, item.attributeName,
                item.type, item.dataType, item.operator, LogicType.And, '');

            attribute.isMultiple = item.isMultiple;
            attribute.values = this.getAttributeValue(item);
            attributes.push(attribute);
        });

        return attributes;
    }
}

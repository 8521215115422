

























































































import {Component, Prop, Vue} from 'vue-property-decorator';
import {AttributeType, OperatorType} from '@/constants/Attribute';
import vSelect from 'vue-select';
import {FilterAttributeResult} from '@/models/attribute/FilterAttributeResult';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import {Vietnamese as VietnameseLocale} from 'flatpickr/dist/l10n/vn.js';
import {Suggestion} from "@/models/Suggestion";
import {AttributeValueResult} from "@/models/attribute/AttributeValueResult";
import axios from 'axios';
import {SearchResult} from "@/models/SearchResult";
import find from 'lodash/find';
import debounce from 'lodash/debounce';
import each from 'lodash/each';
import map from 'lodash/map';
import remove from 'lodash/remove';

@Component({
    components: {
        'v-select': vSelect,
        flatPickr
    }
})

export default class FilterAttribute extends Vue {
    @Prop({default: () => new FilterAttributeResult()}) attribute!: FilterAttributeResult;
    @Prop({default: ''}) id!: string;
    @Prop({default: false}) disabled!: boolean;
    @Prop({default: () => 500}) debounceTime: number;

    debounce: any;
    attributeType = AttributeType;
    operator = OperatorType;
    configDateTimePicker: any = {
        dateFormat: 'd-m-Y',
        locale: VietnameseLocale
    }

    constructor() {
        super();
        if (this.attribute.type !== this.attributeType.DateTime && this.attribute.type !== this.attributeType.NumberInput) {
            this.getAttributeValue();
        }
    }

    // Check value checkbox
    selectValueCheckList(item: Suggestion) {
        const exists = find(this.attribute.attributeValues, (value: AttributeValueResult) => {
            return value.id === item.id;
        });

        if (!exists) {
            const attributeValue: AttributeValueResult = {
                id: item.id,
                name: item.name,
            };
            this.attribute.attributeValues.push(attributeValue)
        } else {
            remove(this.attribute.attributeValues, (value: AttributeValueResult) => {
                return value.id == item.id;
            })
        }

        this.getAttributeValue();
        this.$emit('getValue', this.attribute);
    }

    selectValue(values: any) {
        this.attribute.attributeValues = [];
        if (this.attribute.isMultiple) {
            each(values, (item: any) => {
                if (typeof (item) === "object") {
                    this.attribute.attributeValues.push(item);
                } else {
                    const attributeValue: AttributeValueResult = {
                        id: item,
                        name: item,
                    };
                    this.attribute.attributeValues.push(attributeValue);
                }
            });
        } else {
            this.attribute.attributeValues = [{id: values.id, name: values.name}];
        }

        this.getAttributeValue();
        this.$emit('getValue', this.attribute);
    }

    focus(search: string) {
        if ((!this.attribute.attributeOptions || this.attribute.attributeOptions.length === 0)
            && this.attribute.urlSuggestion !== '') {
            this.search(search, null);
        }
    }

    fetchOptions(search: string, loading: any) {
        if (this.attribute.urlSuggestion !== '') {
            if (this.debounce) {
                this.debounce.cancel();
            }

            this.debounce = debounce(() => {
                this.search(search, loading);
            }, this.debounceTime);

            this.debounce();
        }
    }

    search(search: string, loading: any) {
        if (this.attribute.urlSuggestion && this.attribute.urlSuggestion.length > 0) {
            if (loading) {
                loading(true);
            }
            const response = axios.get(this.attribute.urlSuggestion, {
                params: {
                    searchTerm: search ? search.trim() : '',
                }
            }) as Promise<SearchResult<Suggestion>>;

            response.then((result: SearchResult<Suggestion>) => {
                if (result.code > 0) {
                    this.attribute.attributeOptions = [];
                    this.attribute.attributeOptions = map(result.items, (item: any) => {
                        return {
                            id: item.id,
                            name: item.name,
                            image: item.image ? item.image : item.avatar,
                            data: item
                        }
                    });
                    if (loading) {
                        loading(false);
                    }
                }
            }).catch((e: any) => {
                this.$vs.notify({
                    title: '',
                    text: e.message,
                    color: 'danger'
                });
            })
        }
    }

    private getAttributeValue() {
        this.attribute.attributeValueId = map(this.attribute.attributeValues, (item: AttributeValueResult) => {
            return item.id;
        })
    }
}

// Nhóm người nhân, giao trong phiếu thu, chi
import {PaymentMethod, Status} from "@/constants/Order";

export enum GroupPeopleType {
    /// <summary>
    /// Khách hàng
    /// </summary>
    Customer,

    /// <summary>
    /// Nhân viên
    /// </summary>
    Employee,

    /// <summary>
    /// Đối tác vận chuyển
    /// </summary>
    ShippingPartner,

    /// <summary>
    /// Đối tác khác
    /// </summary>
    OtherPartner
}

export enum ReceiptStatus {
    /// <summary>
    /// Nháp
    /// </summary>
    Draft,
    /// <summary>
    /// Hoàn thành
    /// </summary>
    Complete,
    /// <summary>
    /// Đã hủy
    /// </summary>
    Cancel,
}

export class OptionGroupPeopleType {
    id: number;
    name: string;

    constructor(id: number) {
        this.id = id;
        switch (id) {
            case GroupPeopleType.ShippingPartner:
                this.name = 'Đối tác vận chuyển';
                return;
            case GroupPeopleType.OtherPartner:
                this.name = 'Đối tác khách';
                return;
            case GroupPeopleType.Customer:
                this.name = 'Khách hàng';
                return;
            case GroupPeopleType.Employee:
                this.name = 'Nhân viên';
                return;
        }
    }
}

export class OptionReceiptStatus {
    id: number;
    name: string;

    constructor(id: number) {
        this.id = id;
        switch (id) {
            case ReceiptStatus.Cancel:
                this.name = 'Hủy';
                return;
            case ReceiptStatus.Complete:
                this.name = 'Hoàn thành';
                return;
            case ReceiptStatus.Draft:
                this.name = 'Nháp';
                return;
        }
    }
}

export class OptionPaymentMethod {
    id: number;
    name: string;

    constructor(id: number) {
        this.id = id;
        switch (id) {
            case PaymentMethod.Cash:
                this.name = 'Tiền mặt';
                return;
            case PaymentMethod.COD:
                this.name = 'Giao hàng nhận tiền';
                return;
            case PaymentMethod.PayLater:
                this.name = 'Trả sau';
                return;
            case PaymentMethod.Transfer:
                this.name = 'Chuyển khoản';
                return;
        }
    }
}

export const ListGroupPeopleType = [
    {
        id: GroupPeopleType.Customer,
        name: new OptionGroupPeopleType(GroupPeopleType.Customer).name,
        data: null
    }, {
        id: GroupPeopleType.Employee,
        name: new OptionGroupPeopleType(GroupPeopleType.Employee).name,
        data: null
    }, {
        id: GroupPeopleType.OtherPartner,
        name: new OptionGroupPeopleType(GroupPeopleType.OtherPartner).name,
        data: null
    }, {
        id: GroupPeopleType.ShippingPartner,
        name: new OptionGroupPeopleType(GroupPeopleType.ShippingPartner).name,
        data: null
    }];

export const ListPaymentMethod = [
    {id: PaymentMethod.Cash, name: new OptionPaymentMethod(PaymentMethod.Cash).name, data: null},
    {id: PaymentMethod.COD, name: new OptionPaymentMethod(PaymentMethod.COD).name, data: null},
    {id: PaymentMethod.PayLater, name: new OptionPaymentMethod(PaymentMethod.PayLater).name, data: null},
    {id: PaymentMethod.Transfer, name: new OptionPaymentMethod(PaymentMethod.Transfer).name, data: null}];

export const ListReceiptStatus = [
    {
        id: ReceiptStatus.Complete,
        name: new OptionReceiptStatus(ReceiptStatus.Complete).name,
        data: null
    }, {
        id: ReceiptStatus.Cancel,
        name: new OptionReceiptStatus(ReceiptStatus.Cancel).name,
        data: null
    }
];

export enum ReceiptType {
    /// <summary>
    /// Thu
    /// </summary>
    Receivable,

    /// <summary>
    /// Chi
    /// </summary>
    Payment
}

export class OptionReceiptType {
    id: number;
    name: string;
    namEN: string;

    constructor(id: number) {
        this.id = id;
        switch (id) {
            case ReceiptType.Payment:
                this.name = "Phiếu chi";
                this.namEN = "PaymentVoucher"
                return;
            case ReceiptType.Receivable:
                this.name = "Phiếu thu";
                this.namEN = "ReceiveVoucher"
                return;
        }
    }
}

export enum CodeReferenceType {
    /// <summary>
    /// Đơn hàng
    /// </summary>
    Order,

    /// <summary>
    /// Trả hàng.
    /// </summary>
    OrderReturned,

    /// <summary>
    /// Hợp đồng
    /// </summary>
    Contract,
    /// <summary>
    /// Phiếu nhập.
    /// </summary>
    GoodsReceipt,
    /// <summary>
    /// Phiếu xuất.
    /// </summary>
    GoodsIssue
}

export enum DateSearchType {
    /// <summary>
    /// Ngày tạo
    /// </summary>
    CreateDate = 1,

    /// <summary>
    /// Ngầy ghi nhận
    /// </summary>
    DateRecording = 2
}

export class OptionDateSearchType {
    id: number;
    name: string;

    constructor(id: number) {
        this.id = id;
        switch (id) {
            case DateSearchType.CreateDate:
                this.name = "Ngày tạo";
                return;
            case DateSearchType.DateRecording:
                this.name = "Ngày ghi nhận";
                return;
        }
    }
}

export const ListDateSearchType = [{
    id: DateSearchType.CreateDate,
    name: new OptionDateSearchType(DateSearchType.CreateDate).name
}, {
    id: DateSearchType.DateRecording,
    name: new OptionDateSearchType(DateSearchType.DateRecording).name
}];

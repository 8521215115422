
















import { Component, Vue } from 'vue-property-decorator';

@Component({
    components: {}
})
export default class DropdownCellRenderer extends Vue {
    params: any;

    get source() {
        return this.params.source;
    }

    onItemClick(item: any) {
        if (item.onClick) {
            item.onClick(this.params.data);
        }
    }
}

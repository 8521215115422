import {AttributeType, DataType, LogicType, OperatorType} from '@/constants/Attribute';
import {AttributeValue} from '@/models/attribute/AttributeValue';
import {AttributeScope} from '@/models/attribute/AttributeScope';

export class Attribute {
    id: string;
    attributeId: string;
    attributeName: string;
    type: AttributeType;
    dataType: DataType;
    isDefault: boolean;
    isMultiple: boolean;
    operator: OperatorType;
    logic: LogicType;
    operatorJson: string;
    values: AttributeValue[];
    scopes: AttributeScope[];
    concurrencyStamp: string;

    constructor(id = '', attributeId = '', attributeName = '', type = AttributeType.Input, dataType = DataType.String,
                operator = OperatorType.Equal, logic = LogicType.And, operatorJson = '') {
        this.id = id;
        this.attributeId = attributeId;
        this.attributeName = attributeName;
        this.type = type;
        this.dataType = dataType;
        this.concurrencyStamp = '';
        this.isDefault = true;
        this.isMultiple = false;
        this.values = [];
        this.scopes = [];
        this.operator = operator;
        this.logic = logic;
        this.operatorJson = operatorJson;
    }
}
import axios from 'axios';
import {ActionResult} from "@/models/ActionResult";
import {SettingColumnResult} from "@/models/settingColumn/SettingColumnResult";
import {SettingColumn} from "@/models/settingColumn/SettingColumn";

export class SettingColumnService {
    url = `${process.env.VUE_APP_CORE_URL}api/v1/settingcolumns`;

    getByModuleName(name: string): Promise<ActionResult<SettingColumnResult>> {
        return axios.get(`${this.url}?name=${name}`);
    }

    getUserConfig(): Promise<ActionResult<any>> {
        return axios.get(`${process.env.VUE_APP_CORE_URL}api/v1/users/get-config`);
    }

    insert(settingColumn: SettingColumn): Promise<ActionResult<SettingColumnResult>> {
        return axios.post(`${this.url}`, settingColumn) as Promise<ActionResult<SettingColumnResult>>;
    }

    update(id: string, settingColumn: SettingColumn): Promise<ActionResult<SettingColumnResult>> {
        return axios.put(`${this.url}/${id}`, settingColumn) as Promise<ActionResult<SettingColumnResult>>;
    }
}

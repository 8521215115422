






import {Component, Vue} from "vue-property-decorator";
import ReceiptList from "@/views/cash-book/receipt/receipt-list.vue";
import { ReceiptType } from '@/constants/receipt';

@Component({
    name: 'PaymentVoucher',
    components: {
        ReceiptList
    }
})

export default class PaymentVoucher extends Vue {
    receiptType = ReceiptType;
}

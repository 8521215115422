








import {Component, Vue} from 'vue-property-decorator';
import {ReceiptType} from "@/constants/receipt";

@Component({
    name: 'cellReceiptCodeComponent'
})
export default class CellReceiptCodeComponent extends Vue {
    params: any;

    get url() {
        return {name: this.params.data.type === ReceiptType.Payment ? 'paymentVoucherDetail' : 'receiverVoucherDetail', params: {id: this.params.data.id}};
    }
}

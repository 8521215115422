

































































































import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss';
import {Component, Prop, Ref, Vue, Watch} from "vue-property-decorator";
import FilterList from "@/components/filter/FilterList.vue";
import SettingColumns from "@/components/SettingColumns.vue";
import SelectPageSize from "@/components/select-page-size/select-page-size.vue";
import {AgGridVue} from "ag-grid-vue";
import {FilterAttributeResult} from "@/models/attribute/FilterAttributeResult";
import {ReceiptResult} from "@/models/cash-book/receipt/ReceiptResult";
import {QueryLoadOption, SortingInfo} from "@/models/QueryLoadOptions";
import {SettingColumnResult, SettingColumnSelect} from "@/models/settingColumn/SettingColumnResult";
import {SettingColumnService} from "@/services/SettingColumnService";
import {ReceiptService} from "@/views/cash-book/receipt/receipt.service";
import DropdownCellRenderer from "@/components/ag-grid/DropdownCellRenderer.vue";
import {AttributeType, DataType, LogicType, OperatorType} from "@/constants/Attribute";
import {OptionReceiptStatus, OptionReceiptType, ReceiptStatus, ReceiptType} from "@/constants/receipt";
import {FilterStateResult} from "@/models/filter/filter-state-result";
import filter from 'lodash/filter';
import map from 'lodash/map';
import {ActionResult} from "@/models/ActionResult";
import {ReceiptDetail} from "@/models/cash-book/receipt/ReceiptDetail";
import moment from 'moment';
import CellReceiptStatus from "@/views/cash-book/receipt/cell-render/CellReceiptStatus.vue";
import {formatNumber} from "@/common";
import {SearchResult} from "@/models/SearchResult";
import CustomerCellComponent from "@/components/ag-grid/CustomerCellComponent.vue";
import CellReceiptCertificateCode from './cell-render/CellReceiptCertificateCode.vue';
import {Getter} from "vuex-class";
import CellReceiptCodeComponent from "@/views/cash-book/receipt/cell-render/CellReceiptCode.vue";

@Component({
    name: 'ReceiptList',
    components: {
        FilterList,
        AgGridVue,
        SelectPageSize,
        SettingColumns
    }
})

export default class ReceiptList extends Vue {
    @Getter("oidcUser") oidcUser!: any;
    @Ref('agGridTable') agGridTable!: any;
    @Ref('filter') filter!: any;
    @Ref('selectPageSize') selectPageSize!: any;
    @Ref('settingColumnsRef') settingColumnsRef!: any;
    @Prop({default: () => ReceiptType.Payment}) type: ReceiptType;
    moduleName: string;
    pageName: string;
    searchQuery = '';
    gridOptions: any = {};
    maxPageNumbers = 7;
    gridApi: any = null;
    defaultColDef = {
        sortable: false,
        editable: false,
        resizable: true,
        suppressMenu: true
    };
    columnDefs: any = null;
    totalPages = 0;
    totalRows = 0;
    frameworkComponents: any = null;
    receipts: ReceiptResult[] = [];
    currentPage = 1;
    attributeOptions: FilterAttributeResult[] = [];

    // togge sidebar
    wasSidebarOpen: any = null;

    //setting column
    defaultSettingColumns: any[] = [];
    settingColumnConfig: SettingColumnResult = new SettingColumnResult();

    // Search loadOption
    loadOptions: QueryLoadOption = new QueryLoadOption();
    attributeSearchByKeywords = ['normalizedName', 'codeReference'];
    selectFieldRequired: string[] = ['id', 'customerId', 'referenceType', 'dateRecording', 'creatorId', 'isAutomation'];
    listCustomTab: any[] = [];
    receiptSelect: ReceiptResult;
    receiptType = ReceiptType;

    private receiptService: ReceiptService;
    private settingColumnService: SettingColumnService = new SettingColumnService();

    constructor() {
        super();
        this.receiptService = new ReceiptService();
        this.loadOptions.sort = [new SortingInfo('createTime')];
        this.moduleName = new OptionReceiptType(this.type).namEN;
        this.pageName = new OptionReceiptType(this.type).name.toLowerCase();
    }

    beforeMount() {
        this.listCustomTab = [
            {
                id: 'receiptComplete',
                title: new OptionReceiptType(this.type).name + ' hoàn thành',
                filter: this.getReceiptComplete()
            }
        ];

        this.frameworkComponents = {
            'dropdownCellRenderer': DropdownCellRenderer,
            'cellReceiptStatus': CellReceiptStatus,
            'customerCellComponent': CustomerCellComponent,
            'cellReceiptCodeComponent': CellReceiptCodeComponent,
            'cellReceiptCertificateCode': CellReceiptCertificateCode
        };

        this.defaultSettingColumns = this.getDefaultColumnSetting();
        this.gridOptions = {
            localeText: {noRowsToShow: this.type === ReceiptType.Payment ? 'Không tìm thấy phiếu chi nào.' : 'Không tìm thấy phiếu thu nào'},
        };
        this.initial();
    }

    mounted() {
        this.renderAttributeOptions();
        this.gridApi = this.gridOptions.api;
        this.gridOptions.suppressRowTransform = true;

        //this.gridApi.sizeColumnsToFit();
        /* =================================================================
          NOTE:
          Header is not aligned properly in RTL version of agGrid table.
          However, we given fix to this issue. If you want more robust solution please contact them at gitHub
        ================================================================= */
        if (this.$vs.rtl) {
            const header = this.agGridTable.$el.querySelector('.ag-header-container');
            header.style.left = '-' + String(Number(header.style.transform.slice(11, -3)) + 9) + 'px';
        }

        this.wasSidebarOpen = this.$store.state.reduceButton;
        this.$store.commit('TOGGLE_REDUCE_BUTTON', true);
    }

    beforeDestroy() {
        if (!this.wasSidebarOpen) this.$store.commit('TOGGLE_REDUCE_BUTTON', false)
    }

    get paginationPageSize() {
        if (this.gridApi) return this.gridApi.paginationGetPageSize();
        else return 20;
    }

    get rowData() {
        return this.receipts;
    }

    get currentUser() {
        return this.oidcUser;
    }

    @Watch('currentPage')
    onCurrentPageChange(value: number, oldValue: number) {
        this.search(false);
    }

    initial() {
        /// Lấy danh mục
        this.getColumnDefaultApi();
        this.settingColumnService.getByModuleName(this.moduleName)
            .then((result: ActionResult<SettingColumnResult>) => {
                this.settingColumnConfig = result.data as SettingColumnResult;
                if (!this.settingColumnConfig) {
                    this.settingColumnConfig = new SettingColumnResult(this.moduleName);
                    this.settingColumnConfig.columnConfigData = this.defaultSettingColumns;
                }

                this.getFieldSelectFromResult();
                this.settingColumnsRef.agGridRefresh(this.settingColumnConfig);

                const filterState: FilterStateResult = this.$store.getters['filter/getFilter'](this.$route.fullPath);
                if (!filterState || filterState.searchValue !== null) {
                    this.search();
                }

                if (this.gridApi) {
                    this.gridApi.refreshCells();
                }
            });
    }

    onSortChange(e: any) {
        const columnSort = e.api.getSortModel();
        if (columnSort && columnSort[0]) {
            this.loadOptions.sort = [new SortingInfo(columnSort[0].colId, columnSort[0].sort === 'desc')];

            this.setFilterState(this.currentPage, this.paginationPageSize, this.loadOptions);
            this.search(false);
        }
    }

    onRowDragEnd(e: any) {
        //this.gridApi.resetRowHeights()
    }

    showDetail(receiptDetail: ReceiptDetail) {
        if (receiptDetail) {
            this.$router.push({
                name: this.type == this.receiptType.Payment ? 'paymentVoucherDetail'
                    : 'receiverVoucherDetail', params: {id: receiptDetail.id}
            });
        }
    }

    deleteReceipt(receipt: ReceiptResult) {
        this.receiptSelect = receipt;
        this.$vs.dialog({
            type: 'confirm',
            color: 'danger',
            title: this.$t('ConfirmDelete'),
            acceptText: this.$t('Accept'),
            cancelText: this.$t('Cancel'),
            text: this.$t('ConfirmDeleteText', {data: receipt.code}),
            accept: this.cancel
        })
    }

    cancel() {
        if (this.receiptSelect) {
            this.receiptService.cancel(this.receiptSelect.id).then((result: ActionResult) => {
                this.$vs.notify({
                    color: result.code <= 0 ? 'danger' : 'success',
                    title: 'Thông báo',
                    text: result.message
                })

                if (result.code > 0) {
                    this.search();
                }
            }).catch((err: any) => {
                this.$vs.notify({
                    color: 'danger',
                    title: 'Thông báo',
                    text: err.message
                })
            })
        }
    }

    changePageSize(pageSize: number) {
        this.gridApi.paginationSetPageSize(pageSize);
        this.currentPage = 1;

        this.setFilterState(this.currentPage, pageSize, this.loadOptions);
        this.search();
    }

    beginSearch(page: number) {
        if (this.searchQuery == undefined || this.searchQuery.length < 2) {
            return;
        }
        this.search();
    }

    searchByFilter(filterQuery: string) {
        this.loadOptions.filterQuery = filterQuery;
        this.currentPage = 1;
        this.search();
    }

    getRowStyle(params: any) {
        if (params.node.rowPinned) {
            return {"font-weight": "bold"};
        }
    }

    // Setting clumn
    openSettingColumnModal() {
        this.settingColumnsRef.openSettingColumnModal();
    }

    // Cập nhật column
    settingColumnUpdated(config: SettingColumnResult) {
        this.settingColumnConfig = config;
        this.getFieldSelectFromResult();
        this.search();
    }

    exportExcel() {
    }

    // Show bộ lọc
    showModalFilter(value: FilterAttributeResult[]) {
        if (!value || value.length === 0) {
            this.renderAttributeOptions();
        }
    }

    private openLoading(value: boolean) {
        if (value) {
            this.$vs.loading()
        } else {
            this.$vs.loading.close();
        }
    }

    // Tìm kiếm
    private search(isGetTotal = true) {
        this.openLoading(true);
        const filterState: FilterStateResult = this.$store.getters['filter/getFilter'](this.$route.fullPath);
        if (filterState) {
            this.loadOptions.filterQuery = filterState.loadOption.filterQuery;
            this.gridApi.paginationSetPageSize(filterState.pageSize);
        }

        this.loadOptions.skip = (this.currentPage - 1) * this.paginationPageSize;
        this.loadOptions.take = this.paginationPageSize;

        this.receiptService.search(this.loadOptions, this.type).finally(() => {
            this.openLoading(false);
        }).then((result: SearchResult<ReceiptResult>) => {
            this.receipts = result.items;
            this.setFilterState(this.currentPage, this.paginationPageSize);
            setTimeout(() => {
                if (isGetTotal) {
                    this.receiptService.countByQuery(this.loadOptions, this.type).then((result: number) => {
                        this.setTotalPage(result);
                    })
                } else {
                    this.setTotalPage(this.totalRows);
                }
            }, 200)
        });
    }

    /// Set total Page
    private setTotalPage(totalRows: number) {
        this.totalRows = totalRows;
        if (totalRows == 0) {
            this.totalPages = 0;
        } else if (totalRows <= this.paginationPageSize) {
            this.totalPages = 1;
        } else {
            this.totalPages = Math.ceil((totalRows / this.paginationPageSize));
        }
    }

    /// Tạo các thuộc tính tìm kiếm
    private renderAttributeOptions() {
        this.attributeOptions = this.receiptService.getReceiptAttributeOptions(this.type);
    }

    private getColumnDefaultApi() {
        this.columnDefs = [
            {
                headerName: '',
                field: 'actions',
                filter: false,
                width: 50,
                pinned: 'left',
                suppressSizeToFit: true,
                cellRenderer: 'dropdownCellRenderer',
                headerClass: 'items-center',
                cellClass: 'flex content-center',
                cellRendererParams: (parmas: any) => {
                    return {
                        source: [
                            {
                                icon: 'AlignJustifyIcon',
                                text: 'Chi tiết',
                                svgClass: 'fill-current text-success text-xs',
                                onClick: this.showDetail
                            },
                            {
                                icon: 'Trash2Icon',
                                text: 'Hủy',
                                svgClass: 'text-danger text-xs',
                                disabled: moment(parmas.data.dateRecording).format('DD-MM-YYYY') !== moment().format('DD-MM-YYYY')
                                    || parmas.data.status === ReceiptStatus.Cancel
                                    || parmas.data.creatorId !== this.currentUser.userId || parmas.data.isAutomation,
                                onClick: this.deleteReceipt
                            }
                        ]
                    }
                }
            },
            {
                headerName: '',
                field: 'selection',
                filter: false,
                checkboxSelection: true,
                headerCheckboxSelectionFilteredOnly: false,
                headerCheckboxSelection: true,
                width: 50,
                pinned: 'left',
                suppressSizeToFit: true,
            },
            {
                headerName: this.$t(this.moduleName + '.code'),
                field: 'code',
                filter: true,
                sortable: false,
                width: 180,
                pinned: 'left',
                suppressSizeToFit: true,
                editable: false,
                cellRenderer: 'cellReceiptCodeComponent'
            },
            {
                headerName: this.$t(this.moduleName + '.receiptGroupName'),
                field: 'receiptGroupName',
                filter: true,
                sortable: false,
                width: 200,
                editable: false,
                pinned: 'left',
                suppressSizeToFit: true
            },
            {
                headerName: this.$t(this.moduleName + '.isAutomation'),
                field: 'isAutomation',
                filter: true,
                sortable: false,
                width: 150,
                editable: false,
                cellRenderer: function (parmas: any) {
                    if (parmas.value) {
                        return 'Tự động'
                    } else {
                        return 'Tạo phiếu'
                    }
                },
            },
            {
                headerName: this.$t(this.moduleName + '.customerName'),
                field: 'customerName',
                filter: true,
                sortable: false,
                width: 180,
                editable: false,
                cellRenderer: 'customerCellComponent'
            }, {
                headerName: this.$t(this.moduleName + '.receiptFullName'),
                field: 'receiptFullName',
                filter: true,
                sortable: false,
                width: 180,
                editable: false
            },
            {
                headerName: this.$t(this.moduleName + '.paymentMethodString'),
                field: 'paymentMethodString',
                filter: true,
                sortable: false,
                width: 200,
                editable: false,
            },
            {
                headerName: this.$t(this.moduleName + '.status'),
                field: 'status',
                filter: true,
                sortable: false,
                width: 200,
                editable: false,
                cellRenderer: 'cellReceiptStatus'
            },
            {
                headerName: this.$t(this.moduleName + '.creatorFullName'),
                field: 'creatorFullName',
                filter: true,
                sortable: false,
                width: 200,
                editable: false
            }, {
                headerName: this.$t(this.moduleName + '.amount'),
                field: 'amount',
                filter: true,
                resizable: true,
                width: 150,
                editable: false,
                cellRenderer: function (parmas: any) {
                    if (parmas.value) {
                        return formatNumber(parmas.value)
                    } else {
                        return 0
                    }
                },
                cellStyle: {textAlign: "right"}
            }, {
                headerName: this.$t(this.moduleName + '.dateRecording'),
                field: 'dateRecording',
                filter: true,
                sortable: false,
                width: 200,
                editable: false,
                cellRenderer: function (params: any) {
                    if (params.value) {
                        return moment(params.value).format('DD-MM-YYYY HH:mm');
                    }
                }
            },
            {
                headerName: this.$t(this.moduleName + '.createTime'),
                field: 'createTime',
                filter: true,
                sortable: false,
                width: 200,
                editable: false,
                cellRenderer: function (params: any) {
                    return `<i class="text-sm">` + moment(params.value).format('DD-MM-YYYY HH:mm') + `</i>`
                }
            },
            {
                headerName: this.$t(this.moduleName + '.branchName'),
                field: 'branchName',
                editable: false,
                width: 160,
                filter: true
            }, {
                headerName: this.$t(this.moduleName + '.isAccounting'),
                field: 'isAccounting',
                cellRenderer: function (params: any) {
                    if (params.value) {
                        return 'Có';
                    } else {
                        return 'Không';
                    }
                },
                editable: false,
                width: 100,
            }, {
                headerName: this.$t(this.moduleName + '.codeReference'),
                field: 'codeReference',
                filter: true,
                sortable: false,
                width: 250,
                editable: false,
                cellRenderer: 'cellReceiptCertificateCode'
            }
        ];
    }

    private getDefaultColumnSetting() {
        return this.receiptService.getDefaultColumnSetting(this.type);
    }

    private getFieldSelectFromResult() {
        const columnSelect = filter(this.settingColumnConfig.columnConfigData, (item: SettingColumnSelect) => {
            return item.isSelected;
        });

        const fieldSelect = map(columnSelect,
            (item: SettingColumnSelect) => {
                return item.field
            });

        this.loadOptions.select = this.selectFieldRequired.concat(fieldSelect).filter((idx: string) => {
            return idx !== 'actions' && idx !== 'selection';
        });

        if (!fieldSelect || fieldSelect.length < 5) {
            this.gridApi.sizeColumnsToFit();
        }
    }

    private setFilterState(currentPage: number, pageSize: number, loadOption ?: QueryLoadOption) {
        let filterState: FilterStateResult = this.$store.getters['filter/getFilter'](this.$route.fullPath);
        if (!filterState) {
            filterState = new FilterStateResult();
        }

        filterState.pageSize = pageSize;
        filterState.currentPage = currentPage;
        filterState.loadOption = loadOption ? loadOption : filterState.loadOption;
        filterState.module = this.$route.fullPath;
        filterState.attributeOptions = this.attributeOptions;

        // Cập nhật lại filter state
        this.$store.dispatch('filter/setFilterState', filterState);
    }

    private getReceiptComplete() {
        return {
            id: '',
            name: this.type == ReceiptType.Payment ? "Phiếu chi hoàn thành" : "Phiếu thu hoàn thành",
            routerName: "receipt",
            userId: "",
            fullName: "",
            operatorJson: "",
            concurrencyStamp: "",
            attributes: [
                {
                    id: "",
                    attributeId: "status",
                    attributeName: "Trạng thái",
                    dataType: DataType.Byte,
                    type: AttributeType.CheckList,
                    isDefault: true,
                    isMultiple: true,
                    operator: LogicType.And,
                    values: [
                        {
                            id: ReceiptStatus.Complete,
                            Name: new OptionReceiptStatus(ReceiptStatus.Complete).name,
                            Operator: OperatorType.Equal,
                            Logic: LogicType.Or
                        }
                    ],
                    logic: LogicType.And,
                    operatorJson: "",
                    concurrencyStamp: "",
                    scopes: []
                }
            ]
        }
    }
}










import {Component, Vue} from 'vue-property-decorator';

@Component({
    name: 'customerCellComponent'
})
export default class CustomerCellComponent extends Vue {
    params: any;

    get url() {
        return {name: 'customerDetail', params: {id: this.params.data.customerId}};
    }
}

import axios from 'axios';
import { SearchResult } from "@/models/SearchResult";
import { Filter } from "@/models/filter/filter";
import { ActionResult } from "@/models/ActionResult";

export class FilterService {
    url = `${process.env.VUE_APP_CORE_URL}api/v1/filter`;

    // Tìm kiếm bộ lọc theo routerName
    suggestion(routerName: string, keyword: string, page: 1, pageSize: 20): Promise<SearchResult<Filter>> {
        return axios.get(`${this.url}/suggestion`, {
            params: {
                routerName: routerName,
                keyword: keyword,
                page: page,
                pageSize: pageSize
            }
        })
    }

    // Lấy chi tiết bộ lọc
    getDetail(id: string): Promise<ActionResult<Filter>> {
        return axios.get(`${this.url}/${id}`, {})
    }

    // Xóa bộ lọc
    delete(id: string): Promise<ActionResult> {
        return axios.delete(`${this.url}/${id}`)
    }

    // Thêm mới bộ lọc
    insert(filter: Filter): Promise<ActionResult<Filter>> {
        return axios.post(`${this.url}`, filter);
    }

    // Cập nhật bộ lọc
    update(id: string, filter: Filter): Promise<ActionResult<Filter>> {
        return axios.post(`${this.url}/${id}`, filter)
    }
}

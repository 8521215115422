



























































import {Component, Prop, Ref, Vue, Watch} from "vue-property-decorator";
import FilterModal from "../../components/filter/filter-modal/FilterModal.vue";
import {FilterAttributeResult} from "@/models/attribute/FilterAttributeResult";
import {Suggestion} from "@/models/Suggestion";
import {FilterService} from "@/components/filter/FilterService";
import {SearchResult} from "@/models/SearchResult";
import {ActionResult} from "@/models/ActionResult";
import {Filter} from "@/models/filter/filter";
import findIndex from 'lodash/findIndex';
import debounce from 'lodash/debounce';
import find from 'lodash/find';
import filter from 'lodash/filter';
import each from 'lodash/each';
import map from 'lodash/map';
import moment from 'moment';
import {Attribute} from "@/models/attribute/Attribute";
import {AttributeValue} from "@/models/attribute/AttributeValue";
import {
    AttributeType,
    DataType,
    ListOperator,
    ListOperatorSuggestion,
    LogicType,
    OperatorType
} from "@/constants/Attribute";
import {Operator} from "@/models/attribute/Operator";
import {AttributeValueResult} from "@/models/attribute/AttributeValueResult";
import {Common} from "@/helper/common";
import {FilterStateResult} from "@/models/filter/filter-state-result";

@Component({
    components: {
        FilterModal
    }
})

export default class FilterList extends Vue {
    @Ref('filterModal') filterModal!: any;
    @Prop({default: () => []}) attributeOptions: FilterAttributeResult[];
    @Prop({default: () => 'Lọc khách hàng'}) title: string;
    @Prop({default: () => 'Tìm kiếm...'}) searchPlaceHolder: string;
    @Prop({default: () => []}) attributeSearchByKeywords: string[];
    @Prop({default: () => []}) customTabs!: any;
    @Prop({default: () => ''}) moduleName: string;
    @Prop({default: () => ''}) pageName: string;

    driver = '';
    searchQuery = '';
    listFilter: Filter[] = [];
    listAttributeResult: FilterAttributeResult[] = [];
    selectItem: Filter;
    filter: Filter = new Filter();
    tabIndex: number = 0;
    listAttributeResultSearching: FilterAttributeResult[] = [];
    forceRender: number = 0;
    filterDescription = '';
    common = new Common();
    debounce: any = null;
    currentPage: number = 1;
    pageSize: number = 20;

    private _options: FilterAttributeResult[];
    private filterService = new FilterService();

    constructor() {
        super();
    }

    mounted() {
        // Get listFilter
        const filterState: FilterStateResult = this.$store.getters['filter/getFilter'](this.$route.fullPath);
        if (!filterState || !filterState.listFilter || filterState.listFilter.length === 0) {
            setTimeout(() => {
                this.filterService.suggestion(this.moduleName, '', 1, 20)
                    .then((result: SearchResult<Filter>) => {
                        if (result.code > 0) {
                            this.listFilter = result.items;
                        }
                    })
            }, 1000);
        }

        if (filterState) {
            // setTimeout(() => {
            this.driver = filterState.driver;
            this.searchQuery = filterState.searchValue;
            this.listFilter = filterState.listFilter;
            this.filter = filterState.filter;
            this.tabIndex = filterState.tabIndex;
            this.options = filterState.attributeOptions;

            //     console.log(this.tabIndex);
            // }, 1100)
        }
    }

    get options(): FilterAttributeResult[] {
        this._options = this.attributeOptions.filter((item: FilterAttributeResult) => {
            return item.attributeName;
        });

        return this._options;
    }

    set options(value: FilterAttributeResult[]) {
        this._options = value;
    }

    get customTabCount() {
        return this.customTabs.length + 1;
    }

    @Watch('listFilter')
    changeListFilter(value: any[]) {
        setTimeout(() => {
            this.forceRender += 1;
            this.listAttributeResult = this.convertListAttributeToAttributeResult(this.filter.attributes);
        }, 100)
    }

    search() {
        this.renderFilterQuery();
    }

    onkeydown(value: any) {
        if (this.debounce) {
            this.debounce.cancel();
        }

        if (value !== undefined) {
            this.debounce = debounce(() => this.renderFilterQuery(), 600);
            this.debounce();
        }
    }

    // Hiển thị báo cáo.
    customTabClick(tab: any, index: number) {
        this.tabIndex = index + 1;
        if (!tab.filter) {
            this.$emit('customTabSelected', tab);
        } else {
            this.filter = tab.filter;
            this.listAttributeResult = this.convertListAttributeToAttributeResult(tab.filter.attributes);
            this.renderFilterQuery();
        }
    }

    // Chọn all khách hàng
    selectAll() {
        this.listAttributeResult = [];
        this.filter = new Filter();
        this.tabIndex = 0;
        this.renderFilterQuery();
    }

    // Chọn từng tab
    selectFilter(tab: Suggestion, index: number) {
        this.searchQuery = '';
        this.driver = '';
        this.tabIndex = index + 1 + this.customTabs.length;
        if (tab.id !== '-1') {
            // this.filterService.getDetail(tab.id).then((result: ActionResult<Filter>) => {
            //     if (result.code > 0 && result.data) {
            //         this.filter = result.data;
            const filterInfo = find(this.listFilter, (item: Filter) => {
                return item.id === tab.id
            })

            if (filterInfo) {
                this.listAttributeResult = this.convertListAttributeToAttributeResult(filterInfo.attributes);
                this.renderFilterQuery();
            }
            //     }
            // })
        } else {
            this.listAttributeResult = this.listAttributeResultSearching;
            this.renderFilterQuery();
        }
    }

    removeFilter(item: any) {
        const filterByIndex = this.listFilter[item.id - 1 - this.customTabs.length];
        this.selectItem = filterByIndex;
        if (this.selectItem && this.selectItem.id !== '-1') {
            if (filterByIndex) {
                this.$vs.dialog({
                    type: 'confirm',
                    color: 'danger',
                    title: this.$t('Confirm delete'),
                    acceptText: this.$t('Accept'),
                    cancelText: this.$t('Cancel'),
                    text: this.$t('ConfirmDeleteMessage', ['bộ lọc', this.selectItem.name]),
                    accept: this.delete
                })
            }
        } else {
            this.$delete(this.listFilter, item.id - 1 - this.customTabs.length);
            this.afterRemoveTab(item.id);
        }
    }

    acceptFilter(data: any) {
        this.listAttributeResult = data.attributes;
        const isSave: boolean = data.isSave;
        const filter: Filter = data.filter;
        this.filter = data.filter;

        if (filter.id === '-1') {
            this.listAttributeResultSearching = data.attributes;
        }

        if (filter) {
            const filterById = this.listFilter.find((item: Filter) => {
                return item.id === filter.id || item.id === '-1';
            })

            if (filterById) {
                filterById.name = filter.name;
                filterById.id = filter.id;

                const filterTabIndex = findIndex(this.listFilter, (item: Filter) => {
                    return item.id === filterById.id;
                })

                setTimeout(() => {
                    this.tabIndex = this.customTabs.length + 1 + filterTabIndex;
                }, 50)
            } else {
                this.listFilter.push(filter);
            }

            if (!filterById) {
                setTimeout(() => {
                    this.tabIndex = this.listFilter.length + this.customTabs.length;
                    this.renderFilterQuery();
                }, 50);
            } else {
                this.renderFilterQuery();
            }
        }
    }

    showFilterModal() {
        this.$emit('showModalFilter', this.options);
        this.filterModal.show(this.filter, this.listAttributeResult)
    }

    private delete() {
        this.filterService.delete(this.selectItem.id).then((result: ActionResult) => {
            if (result.code > 0) {
                const index = this.listFilter.findIndex((filter: Filter) => {
                    return filter.id === this.selectItem.id;
                });

                this.$delete(this.listFilter, index);
                this.afterRemoveTab(index + this.customTabs.length + 1);
                this.$vs.notify({
                    title: "Thông báo",
                    text: result.message,
                    color: 'success',
                    iconPack: 'feather',
                    icon: 'icon-info'
                });

                //location.reload();
            }
        }).catch((e) => {
            this.$vs.notify({
                title: '',
                text: e.message,
                color: 'danger'
            });
        });
    }

    private convertAttributeResult(attribute: Attribute, filterAttributeResult: FilterAttributeResult) {
        if (attribute && attribute.values.length > 0) {
            if ((attribute.type === AttributeType.Input && attribute.isMultiple)
                || attribute.type === AttributeType.CheckList
                || attribute.type === AttributeType.DropdownList) {
                filterAttributeResult.attributeValues = map(attribute.values, (item: AttributeValue) => {
                    return {
                        id: this.getValueByDataType(item.id, attribute.dataType),
                        name: item.name,
                    }
                })
            }

            if (attribute.type === AttributeType.NumberInput || attribute.type === AttributeType.DateTime) {
                if (attribute.operator !== OperatorType.Between) {
                    filterAttributeResult.value = attribute.values[0].id
                } else {
                    const valueGreater = attribute.values.find(x => x.operator === OperatorType.GreaterThanOrEqual);
                    if (valueGreater) {
                        filterAttributeResult.value = this.getValueByDataType(valueGreater.id, attribute.dataType);
                    }

                    const valueLess = attribute.values.find(x => x.operator === OperatorType.LessThanOrEqual);
                    if (valueLess) {
                        filterAttributeResult.valueTo = this.getValueByDataType(valueLess.id, attribute.dataType);
                    }
                }
            }

            if (attribute.type === AttributeType.Input && !attribute.isMultiple) {
                filterAttributeResult.value = this.getValueByDataType(attribute.values[0].id, attribute.dataType);
            }
        }
    }

    private getValueByDataType(value: string, type: DataType) {
        switch (type) {
            case DataType.Bit:
                return value === 'null' ? null : value === 'true' ? true : false;
            case DataType.DateTime:
                return value;
            case DataType.Decimal:
                return parseFloat(value);
            case DataType.Double:
                return parseFloat(value);
            case DataType.Byte:
                return parseInt(value);
            case DataType.Int:
                return parseInt(value);
            case DataType.Long:
                return parseFloat(value);
            case DataType.Short:
                return parseInt(value);
            default:
                return value;
        }
    }

    private renderFilterQuery() {
        const listFilterAttribute = filter(this.listAttributeResult, (item: FilterAttributeResult) => {
            return (item.attributeValues && item.attributeValues.length > 0) || item.value !== undefined || item.valueTo !== undefined
        })

        let filterQuery: any[] = [];
        if (listFilterAttribute !== null && listFilterAttribute.length > 0) {
            each(listFilterAttribute, (attributeResult: FilterAttributeResult, index) => {
                const attributeId = this.common.camelCase(attributeResult.attributeId);

                const logic = this.getLogicValue(attributeResult.logic);

                this.filterDescription += `${attributeResult.attributeName} :`;

                if ((attributeResult.type === AttributeType.Input && attributeResult.isMultiple)
                    || attributeResult.type === AttributeType.CheckList
                    || attributeResult.type === AttributeType.DropdownList) {

                    let query: any[] = [];
                    each(attributeResult.attributeValues, (value: AttributeValueResult, idx) => {
                        query.push(this.common.renderQuery(attributeId, this.getOperatorValue(attributeResult.operator), value.id));
                        this.filterDescription += ` ${this.getOperatorName(attributeResult.operator)} ${value.name}`;

                        if (idx < attributeResult.attributeValues.length - 1) {
                            query.push(attributeResult.operator === OperatorType.NotEqual ? this.getLogicValue(LogicType.And) : this.getLogicValue(LogicType.Or))
                            this.filterDescription += `${attributeResult.operator === OperatorType.NotEqual ? ' và ' : ' hoặc '}`;
                        }
                    })

                    filterQuery.push(query);
                    if (index < listFilterAttribute.length - 1) {
                        filterQuery.push(logic);
                        this.filterDescription += `${attributeResult.logic === LogicType.And ? 'và ' : 'hoặc '} ;`
                    }
                }

                if (attributeResult.type === AttributeType.DateTime) {
                    filterQuery.push(this.renderAttributeDateTime(attributeResult, attributeResult.operator));
                }

                if (attributeResult.type === AttributeType.NumberInput) {
                    if (attributeResult.operator !== OperatorType.Between) {
                        filterQuery.push(this.common.renderQuery(attributeId, this.getOperatorValue(attributeResult.operator), attributeResult.value));

                        this.filterDescription += ` ${this.getOperatorName(attributeResult.operator)} ${attributeResult.value}`;
                    } else {
                        const queryValueLess = [attributeId, this.getOperatorValue(OperatorType.GreaterThanOrEqual), attributeResult.value];

                        const queryValueGreater = [attributeId, this.getOperatorValue(OperatorType.LessThanOrEqual), attributeResult.valueTo];

                        const queryValue = [queryValueLess, this.getLogicValue(LogicType.And), queryValueGreater];
                        this.filterDescription += ` ${this.getOperatorName(OperatorType.GreaterThanOrEqual)} ${attributeResult.value}`;

                        if (attributeResult.valueTo) {
                            this.filterDescription += ` và ${this.getOperatorName(OperatorType.LessThanOrEqual)} ${attributeResult.valueTo}`;
                        }

                        filterQuery.push(queryValue);
                    }

                    if (index < listFilterAttribute.length - 1) {
                        filterQuery.push(logic);
                        this.filterDescription += `${attributeResult.logic === LogicType.And ? ' và ' : ' hoặc '} ;`
                    }
                }

                if (attributeResult.type === AttributeType.Input && !attributeResult.isMultiple) {
                    filterQuery.push(this.common.renderQuery(attributeId, this.getOperatorValue(attributeResult.operator), attributeResult.value));
                    this.filterDescription += ` ${this.getOperatorName(attributeResult.operator)} ${attributeResult.value}`;

                    if (index < listFilterAttribute.length - 1) {
                        filterQuery.push(logic);
                        this.filterDescription += `${attributeResult.logic === LogicType.And ? ' và ' : ' hoặc '} ;`
                    }
                }
            })

            if (this.attributeSearchByKeywords && this.attributeSearchByKeywords.length > 0
                && this.searchQuery !== '' && this.searchQuery !== undefined) {
                filterQuery.push('and');
            }
        }

        if (this.attributeSearchByKeywords && this.attributeSearchByKeywords.length > 0
            && this.searchQuery !== '' && this.searchQuery !== undefined && this.searchQuery !== null
            && this.searchQuery.trim() !== '' && this.searchQuery.trim() !== null) {
            let querySearchByKeyword: any = [];

            each(this.attributeSearchByKeywords, (attribute: string, index: number) => {
                if (this.searchQuery.indexOf(',') > -1) {
                    const queryParams: any[] = [];
                    const listKeywordAfterSplit = this.searchQuery.split(',');
                    if (listKeywordAfterSplit && listKeywordAfterSplit.length > 0) {
                        each(listKeywordAfterSplit, (item: string, index: number) => {
                            queryParams.push(this.common.renderQuery(attribute, 'contains',
                                this.common.stripVietnameseChars(item.trim()).toUpperCase()));
                            if (index < listKeywordAfterSplit.length - 1) {
                                queryParams.push('and');
                            }
                        })
                    }
                    querySearchByKeyword.push(queryParams);
                } else {
                    querySearchByKeyword.push(this.common.renderQuery(attribute, 'contains',
                        this.common.stripVietnameseChars(this.searchQuery.trim()).toUpperCase()));
                }
                if (index < this.attributeSearchByKeywords.length - 1) {
                    querySearchByKeyword.push('or');
                }
            })

            filterQuery.push(querySearchByKeyword);
        }

        // set filter state
        let filterStateResult: FilterStateResult = this.$store.getters['filter/getFilter'](this.$route.fullPath);
        if (!filterStateResult) {
            filterStateResult = new FilterStateResult();
        }

        filterStateResult.tabIndex = this.tabIndex;
        filterStateResult.filter = this.filter;
        filterStateResult.loadOption.filterQuery = filterQuery.length == 0 ? '' : JSON.stringify(filterQuery);
        filterStateResult.listFilter = this.listFilter;
        filterStateResult.module = this.$route.fullPath;
        filterStateResult.searchValue = this.searchQuery;
        filterStateResult.driver = this.driver;
        filterStateResult.currentPage = this.currentPage;
        filterStateResult.pageSize = this.pageSize;
        filterStateResult.attributeOptions = filterStateResult ? filterStateResult.attributeOptions : this.attributeOptions;
        this.$store.dispatch('filter/setFilterState', filterStateResult);

        this.$emit('onSearchByFilter', !filterQuery || filterQuery.length == 0 ? '' : JSON.stringify(filterQuery));
    }

    private renderAttributeDateTime(attributeResult: FilterAttributeResult, operatorType: OperatorType) {
        let queryParams: any = [];
        attributeResult.attributeId = this.common.camelCase(attributeResult.attributeId);
        switch (operatorType) {
            case OperatorType.Equal:
                queryParams.push(this.common.renderQuery(attributeResult.attributeId, this.getOperatorValue(OperatorType.GreaterThanOrEqual),
                    moment(attributeResult.value, 'DD-MM-YYYY').toISOString()));

                queryParams.push('and');

                queryParams.push(this.common.renderQuery(attributeResult.attributeId, this.getOperatorValue(OperatorType.LessThanOrEqual),
                    moment(attributeResult.value, 'DD-MM-YYYY').add(1, 'days').add(-1, 'minutes').toISOString()));

                break;
            case OperatorType.GreaterThan:
            case OperatorType.LessThanOrEqual:
                queryParams.push(this.common.renderQuery(attributeResult.attributeId, this.getOperatorValue(attributeResult.operator),
                    moment(attributeResult.value, 'DD-MM-YYYY').add(1, 'days').add(-1, 'minutes').toISOString()));
                break;

            case OperatorType.GreaterThanOrEqual:
            case OperatorType.LessThan:
                queryParams.push(this.common.renderQuery(attributeResult.attributeId, this.getOperatorValue(attributeResult.operator),
                    moment(attributeResult.value, 'DD-MM-YYYY').toISOString()));
                break

            case OperatorType.NotEqual:
                queryParams.push(this.common.renderQuery(attributeResult.attributeId, this.getOperatorValue(OperatorType.LessThan),
                    moment(attributeResult.value, 'DD-MM-YYYY').toISOString()));

                queryParams.push('or');

                queryParams.push(this.common.renderQuery(attributeResult.attributeId, this.getOperatorValue(OperatorType.GreaterThan),
                    moment(attributeResult.value, 'DD-MM-YYYY').add(1, 'days').add(-1, 'minutes').toISOString()));
                break;

            case OperatorType.Between:
                queryParams.push(this.common.renderQuery(attributeResult.attributeId, this.getOperatorValue(OperatorType.GreaterThanOrEqual),
                    moment(attributeResult.value, 'DD-MM-YYYY').toISOString()));

                queryParams.push('and');

                queryParams.push(this.common.renderQuery(attributeResult.attributeId, this.getOperatorValue(OperatorType.LessThanOrEqual),
                    moment(attributeResult.valueTo, 'DD-MM-YYYY').add(1, 'days').add(-1, 'minutes').toISOString()));
                break;
            default:
                queryParams = [];
                break;
        }

        return queryParams;
    }

    private getOperatorValue(value: OperatorType) {
        switch (value) {
            case OperatorType.Equal:
                return '=';
            case OperatorType.NotEqual:
                return '<>';
            case OperatorType.LessThan:
                return '<';
            case OperatorType.LessThanOrEqual:
                return '<=';
            case OperatorType.GreaterThan:
                return '>';
            case OperatorType.GreaterThanOrEqual:
                return '>=';
            case OperatorType.Between:
                return 'between';
            case OperatorType.Contains:
                return 'contains';
            case OperatorType.NotContains:
                return 'notcontains';
            case OperatorType.EndsWith:
                return 'endswith';
            case OperatorType.StartsWith:
                return 'startswith';
            default:
                return '';
        }
    }

    private getOperatorName(value: OperatorType) {
        switch (value) {
            case OperatorType.Equal:
                return 'bằng';
            case OperatorType.NotEqual:
                return 'không băng';
            case OperatorType.LessThan:
                return 'nhỏ hơn';
            case OperatorType.LessThanOrEqual:
                return 'nhỏ hơn hoặc bằng';
            case OperatorType.GreaterThan:
                return 'lớn hơn';
            case OperatorType.GreaterThanOrEqual:
                return 'lớn hơn hoặc bằng';
            case OperatorType.Between:
                return 'between';
            case OperatorType.Contains:
                return 'chứa';
            case OperatorType.NotContains:
                return 'không chứa';
            case OperatorType.EndsWith:
                return 'kết thúc bằng';
            case OperatorType.StartsWith:
                return 'bắt đầu bằng';
            default:
                return '';
        }
    }

    private getLogicValue(value: LogicType) {
        switch (value) {
            case LogicType.And:
                return 'and';
            case LogicType.Or:
                return 'or'
            default:
                return '';
        }
    }

    private afterRemoveTab(tabindex: number) {
        if (this.tabIndex === tabindex) {
            this.filter = new Filter();
            this.listAttributeResult = [];
            this.searchQuery = '';
            this.driver = '';
            this.renderFilterQuery();
            this.tabIndex = 0;
        }
    }

    private convertListAttributeToAttributeResult(listAttributeValue: Attribute[]) {
        const listAttributeResult: FilterAttributeResult[] = [];
        if (listAttributeResult && listAttributeValue.length > 0) {
            each(listAttributeValue, (item: Attribute) => {
                const attributeResult = new FilterAttributeResult();
                attributeResult.attributeId = item.attributeId;
                attributeResult.attributeName = item.attributeName;
                attributeResult.type = item.type;
                attributeResult.operator = item.operator;
                attributeResult.dataType = item.dataType;
                attributeResult.logic = item.logic;
                attributeResult.isMultiple =  item.isMultiple;

                const attributeById = find(this.attributeOptions, (attributeOption: FilterAttributeResult) => {
                    return attributeOption.attributeId === item.attributeId;
                })

                if (attributeById) {
                    attributeResult.urlSuggestion = attributeById.urlSuggestion;
                    attributeResult.attributeOptions = attributeById.attributeOptions;
                }

                if (attributeResult.type === AttributeType.CheckList || attributeResult.type === AttributeType.DropdownList) {
                    attributeResult.listOperator = ListOperatorSuggestion;
                } else {
                    attributeResult.listOperator = filter(ListOperator, (operator: Operator) => {
                        return operator.dataType.indexOf(attributeResult.dataType) > -1;
                    })
                }

                this.convertAttributeResult(item, attributeResult);
                attributeResult.attributeValueId = map(attributeResult.attributeValues, (item: AttributeValueResult) => {
                    return item.id !== null ? item.id : null;
                })

                listAttributeResult.push(attributeResult);
            })
        }

        return listAttributeResult;
    }
}

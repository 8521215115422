




















import {Component, Emit, Prop, Vue} from 'vue-property-decorator';
import {formatNumber} from "@/common";
@Component
export default class SelectPageSize extends Vue {
    @Prop() currentPage!: number;
    @Prop({default: () => 20}) paginationPageSize!: number;
    @Prop({default: () => 0}) totalRows!: number;
    @Prop({default: () => [10, 20, 30]}) listPageSize: number[];
    @Prop({default: () => 'Không có khách hàng'}) notFound: string;

    @Emit('onSelectPageSize')
    selectPage(pageSize: number) {
        return pageSize;
    }

    get currentPageData() {
        return this.currentPage;
    }

    formatNumber(value: number){
        return formatNumber(value)
    }
}

import {FilterAttributeResult} from "@/models/attribute/FilterAttributeResult";
import {QueryLoadOption} from "@/models/QueryLoadOptions";
import {Filter} from "@/models/filter/filter";

export class FilterStateResult {
    module: string;
    driver: string;
    searchValue: string;
    listFilter: Filter[];
    tabIndex: number;
    filter: Filter;
    loadOption: QueryLoadOption;
    currentPage: number;
    pageSize: number;
    attributeOptions: FilterAttributeResult[];

    constructor() {
        this.currentPage = 1;
        this.pageSize = 10;
        this.loadOption = new QueryLoadOption();
        this.filter = new Filter();
        this.tabIndex = 0;
        this.listFilter = [];
        this.attributeOptions = [];
    }
}

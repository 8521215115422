import {QueryLoadOption} from "@/models/QueryLoadOptions";
import {SearchResult} from "@/models/SearchResult";
import axios from "axios";
import {ActionResult} from "@/models/ActionResult";
import {ReceiptDetail} from "@/models/cash-book/receipt/ReceiptDetail";
import {SettingColumnSelect} from "@/models/settingColumn/SettingColumnResult";
import {FilterAttributeResult} from "@/models/attribute/FilterAttributeResult";
import {AttributeType, DataType, LogicType, OperatorType} from "@/constants/Attribute";
import {DateSearchType, ListPaymentMethod, ListReceiptStatus, ReceiptType,} from "@/constants/receipt";
import {Receipt, ReceiptCertificate} from "@/models/cash-book/receipt/Receipt";
import {ReceiptResult} from "@/models/cash-book/receipt/ReceiptResult";
import {ReceiptUser} from "@/models/cash-book/receipt/ReceiptUser";
import {ReceiptGroupSuggestionModel} from "@/models/cash-book/receipt/ReceiptGroupSuggestionModel";
import {RenderCashBookResult} from "@/models/cash-book/RenderCashBookResult";
import {ExportType} from "@/constants/Order";

export class ReceiptService {
    url = `${process.env.VUE_APP_Accountant_URL}api/v1/receipts`;

    search(loadOptions: QueryLoadOption, type: ReceiptType): Promise<SearchResult<ReceiptResult>> {
        return axios.post(`${this.url}/search/${type}`, loadOptions);
    }

    countByQuery(loadOptions: QueryLoadOption, type: ReceiptType): Promise<number> {
        return axios.post(`${this.url}/count-by-query/${type}`, loadOptions);
    }

    searchCashBook(loadOptions: QueryLoadOption, dateType?: DateSearchType, fromDate?: string, toDate?: string)
        : Promise<SearchResult<ReceiptResult>> {
        return axios.post(`${this.url}/cash-book`, {
            loadOptions: loadOptions,
            dateType: dateType,
            fromDate: fromDate ? fromDate : null,
            toDate: toDate ? toDate : null
        });
    }

    renderCashBook(loadOptions: QueryLoadOption, dateType?: DateSearchType, fromDate?: string, toDate?: string)
        : Promise<ActionResult<RenderCashBookResult>> {
        return axios.post(`${this.url}/render-cash-book`, {
            loadOptions: loadOptions,
            dateType: dateType,
            fromDate: fromDate ? fromDate : null,
            toDate: toDate ? toDate : null
        });
    }

    insert(receipt: Receipt): Promise<ActionResult<string>> {
        return axios.post(`${this.url}`, JSON.parse(JSON.stringify(receipt)));
    }

    update(id: string, receipt: Receipt): Promise<ActionResult<string>> {
        return  axios.post(`${this.url}/${id}`, JSON.parse(JSON.stringify(receipt)))
    }

    cancel(id: string): Promise<ActionResult> {
        return axios.delete(`${this.url}/${id}`);
    }

    getDetail(id: string): Promise<ActionResult<ReceiptDetail>> {
        return axios.get(`${this.url}/${id}`);
    }

    suggestionUser(searchTerm: string): Promise<SearchResult<ReceiptUser>> {
        return axios.get(`${this.url}/users/suggestions`, {
            params: {
                searchTerm: searchTerm
            }
        });
    }

    suggestionGroup(searchTerm: string, type: ReceiptType): Promise<SearchResult<ReceiptGroupSuggestionModel>> {
        return axios.get(`${this.url}/groups/suggestions/${type}`, {
            params: {
                searchTerm: searchTerm
            }
        });
    }

    getReceiptCertificates(receiptId: string, code: string): Promise<SearchResult<ReceiptCertificate>> {
        return axios.get(`${this.url}/certificates/${receiptId}/${code}`);
    }

    getReceiptAttributeOptions(type: ReceiptType) {
        return [
            new FilterAttributeResult("CustomerId", "Khách hàng", "", "",
                false, AttributeType.DropdownList, [], [], OperatorType.Equal,
                `${process.env.VUE_APP_CUSTOMER_URL}api/v1/customers/suggestions`, LogicType.And, DataType.String),
            new FilterAttributeResult("receiptPayments.PaymentMethod", "Hình thức thanh toán", "",
                "", true, AttributeType.DropdownList, [], ListPaymentMethod,
                OperatorType.Equal, '', LogicType.And, DataType.Int),
            new FilterAttributeResult("receiptGroupId", "Loại phiếu", "", "",
                true, AttributeType.DropdownList, [], [], OperatorType.Equal,
                `${process.env.VUE_APP_Accountant_URL}api/v1/receipts/groups/suggestions/${type}`, LogicType.And, DataType.String),
            new FilterAttributeResult("createTime", "Ngày tạo", "", "",
                true, AttributeType.DateTime, [], [], OperatorType.Between, "",
                LogicType.And, DataType.DateTime),
            new FilterAttributeResult("dateRecording", "Ngày ghi nhận", "", "",
                true, AttributeType.DateTime, [], [], OperatorType.Between, "",
                LogicType.And, DataType.DateTime),
            new FilterAttributeResult("creatorId", "Người tạo", "", "",
                true, AttributeType.DropdownList, [], [], OperatorType.Equal,
                `${process.env.VUE_APP_CORE_URL}api/v1/users/suggestions`, LogicType.And, DataType.String),
            new FilterAttributeResult("isAccounting", "Hạch toán kết quả KD", "", "",
                true, AttributeType.CheckList, [], [
                    {id: true, name: 'Có', data: null},
                    {id: false, name: 'Không', data: null}
                ], OperatorType.Equal, '', LogicType.And, DataType.Bit),
            new FilterAttributeResult("status", "Trạng thái", "", "",
                true, AttributeType.CheckList, [], ListReceiptStatus, OperatorType.Equal, '',
                LogicType.And, DataType.Byte),
            new FilterAttributeResult("amount", "Số tiền", "", "",
                true, AttributeType.NumberInput, [], [], OperatorType.Between, '',
                LogicType.And, DataType.Decimal),
            new FilterAttributeResult("isAutomation", "Hình thức", "", "",
                true, AttributeType.CheckList, [], [
                    {id: true, name: 'Tự động', data: null},
                    {id: false, name: 'Tạo phiếu', data: null}
                ], OperatorType.Equal, '', LogicType.And, DataType.Bit),
        ]
    }

    getDefaultColumnSetting(type: ReceiptType) {
        return [
            new SettingColumnSelect('Thao tác', 'actions', true),
            new SettingColumnSelect('Chọn', 'selection', true),
            new SettingColumnSelect('Mã', 'code', true),
            new SettingColumnSelect('Loại phiếu', 'receiptGroupName', true),
            new SettingColumnSelect('Hình thức', 'isAutomation', true),
            new SettingColumnSelect('Khách hàng', 'customerName', true),
            new SettingColumnSelect(type === ReceiptType.Payment ? 'Người nhận' : 'Người nộp', 'receiptFullName', true),
            new SettingColumnSelect('Hình thức thanh toán', 'paymentMethodString', true),
            new SettingColumnSelect('Trạng thái', 'status', true),
            new SettingColumnSelect('Người tạo', 'creatorFullName', true),
            new SettingColumnSelect('Số tiền', 'amount', true),
            new SettingColumnSelect('Ngày ghi nhận', 'dateRecording', true),
            new SettingColumnSelect('Ngày tạo', 'createTime', false),
            new SettingColumnSelect('Showroom', 'branchName', false),
            new SettingColumnSelect('Hạch toán kết quả KD', 'isAccounting', false),
            new SettingColumnSelect('Mã tham chiếu', 'codeReference', true),
        ]
    }

    async exportExcel(loadOptions: QueryLoadOption, dateType?: DateSearchType, fromDate?: string,
                      toDate?: string) {
        let result = (await axios.post(`${this.url}/excel`,
            {
                loadOptions: loadOptions,
                dateType: dateType ? dateType : null,
                fromDate: fromDate ? fromDate : null,
                toDate: toDate ? toDate : null
            }, {responseType: 'blob'})) as any;
        const url = window.URL.createObjectURL(new Blob([result]))
        const link = document.createElement('a');
        link.href = url;

        link.setAttribute('download',
            `INKA CRM@Danh sach phieu thu - chi.xlsx`
        ); //or any other extension

        document.body.appendChild(link);
        link.click();
        return true;
    }
}

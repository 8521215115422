






import {Component, Vue} from 'vue-property-decorator';
import {OptionReceiptStatus, ReceiptStatus} from "@/constants/receipt";

@Component({})
export default class CellPaymentVoucherStatus extends Vue {
    params: any;

    get chipColor() {
        return (value: number) => {
            if (value === ReceiptStatus.Complete) {
                return "success";
            }
            if (value === ReceiptStatus.Cancel) {
                return "warning";
            }
        }
    }

    get statusName() {
        return (value: number) => {
            return new OptionReceiptStatus(value).name;
        }
    }
}

export class QueryLoadOption {
    filterQuery: string;
    filter: any[];
    sort: SortingInfo[];
    take: number = 10;
    skip: number = 0;
    userData: {};
    requireTotalCount: boolean = true;
    searchValue: boolean = false;
    requireGroupCount: boolean = false;
    group: GroupInfo[];
    select: string[];
    defaultSort: string;
    remoteSelect: boolean;
    selectedIds: string[];

    constructor() {
        this.filter = [];
        this.requireTotalCount = true;
        this.filterQuery = '';
    }
}

export class SortingInfo {
    selector: string;
    desc: boolean;

    constructor(selector = '', desc = true) {
        this.selector = selector;
        this.desc = desc;
    }
}

export class GroupInfo {
    selector: string;
    desc: boolean;
    isExpanded: boolean;

    constructor(selector: string = '', desc: boolean = false, isExpanded: boolean = false) {
        this.selector = selector;
        this.desc = desc;
        this.isExpanded = isExpanded;
    }
}












import {Component, Vue} from "vue-property-decorator";
import {ReceiptService} from "@/views/cash-book/receipt/receipt.service";
import {ActionResult} from "@/models/ActionResult";
import {ReceiptCertificate} from "@/models/cash-book/receipt/Receipt";
import {CodeReferenceType} from "@/constants/receipt";

@Component({
    name: 'CellReceiptCertificateCode'
})

export default class CellReceiptCertificateCode extends Vue {
    params: any;
    url: string = '';

    get codes() {
        if (this.params.value) {
            const listCode = this.params.value.split(',');
            return listCode;
        }
        return '';
    }

    getUrl(code: string) {
        let receiptService = new ReceiptService();
        receiptService.getReceiptCertificates(this.params.data.id, code).then((result: ActionResult<ReceiptCertificate>) => {
            if (result && result.data) {
                if (this.params.data.referenceType === CodeReferenceType.Order) {
                    this.$router.push('/order/' + result.data.certificateId);
                }
                if (this.params.data.referenceType === CodeReferenceType.OrderReturned) {
                    this.$router.push('/return/' + result.data.certificateId);
                }
            }
        });
    }
}

export class Common {

    public renderQuery(attributeId: string, operator: string, value: string) {
        return [attributeId, operator, value];
    }

    public camelCase(str: string) {
        return str
            .replace(/\s(.)/g, function (a) {
                return a.toUpperCase();
            })
            .replace(/\s/g, '')
            .replace(/^(.)/, function (b) {
                return b.toLowerCase();
            });
    }

    public convertTimeString(value: number) {
        if (value > 0) {
            let g = value / 3600;
            let p = (g >= 1 ? value - g * 3600 : value) / 60;
            let s = value - (g >= 1 ? g * 3600 : 0) - (p >= 1 ? p * 60 : 0);

            return (g >= 1 ? g + 'h' : '') + (p >= 1 ? p + 'p' : '') + (s + 's');
        } else {
            return '';
        }
    }

    public stripVietnameseChars(input: string) {
        let stringBuilder: any = [];
        const strips = ["áàảãạăắằẳẵặâấầẩẫậ", "ÁÀẢÃẠĂẮẰẲẴẶÂẤẦẨẪẬ", "đ", "Đ", "éèẻẽẹêếềểễệ", "ÉÈẺẼẸÊẾỀỂỄỆ", "íìỉĩị", "ÍÌỈĨỊ", "óòỏõọơớờởỡợôốồổỗộ", "ÓÒỎÕỌƠỚỜỞỠỢÔỐỒỔỖỘ",
            "ưứừửữựúùủũụ", "ƯỨỪỬỮỰÚÙỦŨỤ", "ýỳỷỹỵ", "ÝỲỶỸỴ"];

        const replacements = ['a', 'A', 'd', 'D', 'e', 'E', 'i', 'I', 'o', 'O', 'u', 'U', 'y', 'Y'];

        for (var k = 0; k < input.length; k++) {
            // @ts-ignore
            stringBuilder.push(input.charAt(k));
        }
        for (var i = 0; i < stringBuilder.length; i++) {
            for (var j = 0; j < strips.length; j++) {
                if (strips[j].indexOf(stringBuilder[i]) > -1) {
                    stringBuilder[i] = replacements[j];
                }
            }
        }

        return stringBuilder.join("");
    }
}
